import { render, staticRenderFns } from "./TopImpacts.vue?vue&type=template&id=fd5562f2&scoped=true"
import script from "./TopImpacts.vue?vue&type=script&lang=ts"
export * from "./TopImpacts.vue?vue&type=script&lang=ts"
import style0 from "./TopImpacts.vue?vue&type=style&index=0&id=fd5562f2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd5562f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImpactPillOutcomeScore: require('/home/azureuser/actions-runner/_work/assessment-service/assessment-service/frontend/components/ImpactPillOutcomeScore.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VLayout,VRow,VTooltip})
