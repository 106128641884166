import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es7.array.includes.js";
import { GChart } from 'vue-google-charts';
export default defineComponent({
  name: 'TopImpacts',
  components: {
    GChart: GChart
  },
  props: {
    impactsForSelectedAssessments: Array,
    assessments: Array,
    stage: String
  },
  setup: function setup(props) {
    var shortList = ref(true);
    var dialog = ref(false);
    var topImpacts = computed(function () {
      return _toConsumableArray(props.impactsForSelectedAssessments).sort(function (a, b) {
        return a[score.value] > b[score.value] ? -1 : 1;
      }).slice(0, 30);
    });
    var score = computed(function () {
      if (['Outcome-selection venture', 'Outcome-selection reviewer'].includes(props.stage)) return 'impactPotentialPhase1';
      return 'impactPotentialScore';
    });
    return {
      shortList: shortList,
      dialog: dialog,
      topImpacts: topImpacts,
      score: score
    };
  }
});